import React, { useContext, useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    Button,
    Typography,
    makeStyles,
    CircularProgress,
    FormControl,
    Chip,
} from "@material-ui/core";
import addSPOC from './icons/addSPOC.png';
import './manageClient.css';
import ManageClientTable from '../../components/manageClientTable/manageClientTable'
import Header from "../../components/headerAdmin/Header";
import { addClient, updateClient, getClientByOrg } from "../../apis/organisationAdminAPI/manageClient";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import { Autocomplete } from "@material-ui/lab";
import BasicHeader from '../../components/basicHeader/BasicHeader.js';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { getDivisionByOrg } from "../../apis/organisationAdminAPI/manageDivision";
import { UserTypeContext } from "../../AppRoute";
import { Cancel } from "@material-ui/icons";
import checkRequisitionExist from "../../apis/checkRequisitionExists/checkRequisitionExists"

const useStyles = makeStyles((theme) => ({
    iconButton: {
        position: "absolute",
        top: "37%",
        right: "6px"
    },
    errorMsg: { "marginLeft": "5px", color: " #F32013" },
    addIconButton: { marginTop: "5px" }
}
));

const ManageClient = (props) => {
    const { userType, userRecords } = useContext(UserTypeContext);
    const idContextTest = userType === 'Admin' ? props?.location?.state?.id : userRecords?.id;
    const userRecruitmentpartnerId = userType === 'Admin' ? props?.location?.state?.recruitmentPartnerId : userRecords?.recruitmentpartnerId;
    const userRole = userType === 'Admin' ? props?.location?.state?.userType : userRecords?.role?.roleName;
    const userRecrodData = userType === 'Admin' ? props?.location?.state?.userRecords : userRecords;
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [clientId, setClientId] = useState();
    const [clientData, setClientData] = useState([]);
    const [isDataLoaded, setisDataLoaded] = useState(false);
    const [editedRow, setEditedRow] = useState({});
    const [activeDropDown, setActiveDropDown] = useState([
        { name: "True", value: true },
        { name: "False", value: false },
    ]);
    const [isActiveFieldDisabled, setIsActiveFieldDisabled] = useState(true)
    const [divisionLink, setDivisionLink] = useState([{ "divisionName": "", "recruitmentLeader": [] }]);
    const [divisionName, setDivisionName] = useState([])
    const [isUserDataUpdating, setIsUserDataUpdating] = useState(false)
    const [exisitingRequisition, setExisitingRequisition] = useState([])
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const [ClientInfo, setClientInfo] = useState({
        name: "",
        address: "",
        active: { name: "True", value: true },
        clientId: ""
    })

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setEditedRow({});
        setClientInfo({
            name: "",
            address: "",
            active: { name: "True", value: true }
        })
        setIsActiveFieldDisabled(true);
        setDivisionLink([{
            "divisionName": "",
            "recruitmentLeader": []
        }])
        setExisitingRequisition([])
    };
    const handleSubmit = async () => {

        let isValid = true;
        divisionLink.forEach((item) => {
            if (item.divisionName === "" || item.recruitmentLeader.length === 0) {
                isValid = false;
                NotificationManager.error("Please fill in all the fields", "Error", 2000);
                return
            }
        })
        if (ClientInfo.name === "" || ClientInfo.active === null) {
            isValid = false;
            NotificationManager.error("Please fill in all the fields", "Error", 2000);
            return
        }
        if (isValid) {
            let divisionData = [];
            for (let i = 0; i < divisionLink.length; i++) {
                let item = divisionLink[i];
                let divisionItem = {
                    divisionId: item?.divisionName?._id,
                };
                if (item?.recruitmentLeader) {
                    divisionItem.recruitmentLeaderDetails = [];
                    for (let j = 0; j < item.recruitmentLeader.length; j++) {
                        let leader = item.recruitmentLeader[j];
                        divisionItem.recruitmentLeaderDetails.push({
                            id: leader.id,
                            email: leader.email
                        });
                    }
                }

                divisionData.push(divisionItem);
            }

            let payload = {
                "clientName": ClientInfo.name,
                "address": ClientInfo.address,
                "isActive": ClientInfo.active.value,
                "recruitmentpartnerId": userRecruitmentpartnerId,
                "divisionDetails": divisionData
            }
            setIsUserDataUpdating(true)
            if (Object.keys(editedRow).length > 0) {
                try {
                    const response = await updateClient(editedRow._id, payload);
                    if (response.status === 200) {
                        NotificationManager.success("Client details updated successfully", "Success", 2500);
                        handleCloseDialog();
                        allClientsData();
                    } else if (response.status === 202) {
                        const errorMessage = response.data.message;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                } catch (error) {
                    console.log(error)
                    if (error.response.status === 400 && error.response.data.errorCode === 400) {
                        const errorMessage = error.response.data.errMessage;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                }
            }
            else {
                try {
                    const response = await addClient(payload);
                    if (response.status === 200) {
                        NotificationManager.success("Client has been added successfully", "Success", 2500);
                        handleCloseDialog();
                        allClientsData();
                    } else if (response.status === 202) {
                        const errorMessage = response.data.message;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                } catch (error) {
                    console.log(error)
                    if (error.response.status === 400 && error.response.data.errorCode === 400) {
                        const errorMessage = error.response.data.errMessage;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                }
            }
            setIsUserDataUpdating(false)
        }
    };

    const allClientsData = async () => {
        setClientData([]);
        setisDataLoaded(false)
        let payload = {
            recruitmentPartnerId: userRecruitmentpartnerId
        }
        try {
            setisDataLoaded(false)
            let response = await getClientByOrg(payload);

            if (response.status === 200) {
                let arr = []
                let data = response.data.data

                for (let i = 0; i < data.length; i++) {
                    let emails = [];
                    if (data[i].divisionDetails) {
                        for (let j = 0; j < data[i].divisionDetails.length; j++) {
                            let division = data[i].divisionDetails[j];
                            if (division.recruitmentLeaderDetails) {
                                for (let k = 0; k < division.recruitmentLeaderDetails.length; k++) {
                                    emails.push(division.recruitmentLeaderDetails[k].email);
                                }
                            }
                        }
                    }
                    data[i].recruiterLeaderEmails = emails.join(', ');
                    let divisionNames = "";
                    if (data[i].assignedDivisions) {
                        for (let j = 0; j < data[i].assignedDivisions.length; j++) {
                            let division = data[i].assignedDivisions[j][0];
                            divisionNames += division.divisionName 
                            if (j < data[i].assignedDivisions.length - 1) {
                                divisionNames += ", ";
                            }
                        }
                    }
                    data[i].selectedDivisionNames = divisionNames;

                    arr.push(data[i]);
                }

                setClientData(arr);
            }
            setisDataLoaded(true)

        } catch (error) {
            setisDataLoaded(true)
            setClientData([]);
            NotificationManager.error("Something Went Wrong!", "error", 2500)
        }
    };

    const getAllDivision = async () => {
        try {
            let payload = {
                recruitmentpartnerId: userRecruitmentpartnerId,
                isActive: true
            }
            let response = await getDivisionByOrg(payload);
            setDivisionName(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleNameChange = (e) => {
        if (e.target.value) {
            setClientInfo({ ...ClientInfo, name: e.target.value })
        } else {
            setClientInfo({ ...ClientInfo, name: "" })
        }
    }
    const handleAddressChange = (e) => {
        setClientInfo({ ...ClientInfo, address: e.target.value })
    }
    const handleActiveChange = async (e, value) => {
        if (value) {
            setClientInfo({ ...ClientInfo, active: value })
        } else {
            setClientInfo({ ...ClientInfo, active: null })
        }

    }
    const handelEditOpenDialog = () => {
        setOpenDialog(true);
    }


    const divisionChangeHandler = (value, index) => {
        const originalData = [...divisionLink];
        const changingData = originalData[index];
        if (value) {
            changingData["divisionName"] = value;
            changingData["recruitmentLeader"] = [];
        } else {
            changingData["divisionName"] = "";
            changingData["recruitmentLeader"] = [];
        }
        originalData[index] = changingData;
        setDivisionLink(originalData || []);
    }

    const recruitmentLeaderChangeHandler = (value, index) => {

        const originalData = [...divisionLink];
        const changingData = originalData[index];
        if (value) {
            changingData["recruitmentLeader"] = value;
        } else {
            changingData["recruitmentLeader"] = [];
        }
        originalData[index] = changingData;
        setDivisionLink(originalData || []);

    }

    const deleteRecruitmentLeaderHandler = async (option, index) => {
        try {
            let payload = {
                recruitmentLeaderId: option.id,
                recruitmentPartnerId: userRecruitmentpartnerId,
                clientId: ClientInfo.clientId,
                validation: "Client"
            }
            const dataRes = await checkRequisitionExist(payload);
            if (dataRes?.status === 200 && dataRes?.data?.data.length > 0) {
                NotificationManager.error(
                    `${dataRes?.data?.message}`,
                    "error",
                    2500);
            }else if (dataRes.status === 400 && dataRes.errorCode === 400) {
                const errorMessage = dataRes.data.message;
                NotificationManager.error(errorMessage, "Error", 2500);

            }
            else {
                let oldArr = [...divisionLink];
                let recruitmentLeaders = [...oldArr[index].recruitmentLeader];
                const newRecruitmentLeader = recruitmentLeaders.filter((item) => item.id !== option.id);
                oldArr[index] = {
                    ...oldArr[index],
                    recruitmentLeader: newRecruitmentLeader
                };
                setDivisionLink(oldArr);
            }
        } catch (error) {
            NotificationManager.error("Something Went Wrong!", "error", 2500)
        }

    }

    const handleEdit = async (rowData) => {
        setClientInfo({
            ...ClientInfo,
            name: rowData.clientName,
            address: rowData.address,
            active: {
                name: rowData.isActive ? "True" : "False",
                value: rowData.isActive
            },
            clientId: rowData._id
        });
        const transformedDivisionLink = [];
        for (let index = 0; index < rowData.divisionDetails.length; index++) {
            let detail = rowData.divisionDetails[index];
            let filteredDivisionName = divisionName.filter((item) => item._id === detail?.divisionId)[0];

            transformedDivisionLink.push({
                divisionName: filteredDivisionName,
                recruitmentLeader: detail?.recruitmentLeaderDetails
            });
        }

        setDivisionLink(transformedDivisionLink);

        setEditedRow(rowData); // Set the row data when edit button is clicked

        try {
            let payload = {
                recruitmentPartnerId: userRecruitmentpartnerId,
                clientId: rowData?._id,
                validation: "Client"
            }
            const data = await checkRequisitionExist(payload);
            setExisitingRequisition(data?.data?.data)
            const requisitionExists = data?.data?.data?.length;
            if (requisitionExists > 0 && rowData) {
                setIsActiveFieldDisabled(true)
            }
            else {
                setIsActiveFieldDisabled(false)
            }
        } catch (error) {
            NotificationManager.error("Something Went Wrong!", "error", 2500)
        }

        handelEditOpenDialog(); // Open the dialog 
    };
    const goToDashboard = () => {
        history.push({
            pathname: "/dashboard",
            state: {
                userType: userType,
                recruitmentPartnerId: userRecruitmentpartnerId,
                id: idContextTest,
                userRecords: userRecrodData,
                roleName: userRole
            }
        });
    }



    const addDiVisionLink = () => {
        const row = {
            "divisionName": "",
            "recruitmentLeader": []
        };
        if (divisionLink) {
            setDivisionLink([...divisionLink, row])
        }

    }

    const extraDivisionLinkClose = (index, item) => {
        if (checkDisbale_division(item) || checkDisbale_RecruiterLeader(item)) {
            NotificationManager.error("Unable to make changes as a requisition already exists for this client and division.", "Error", 2500);
        } else {
            let originalData = [...divisionLink];
            originalData.splice(index, 1);
            setDivisionLink(originalData || []);
        }

    }


    const checkDisbale_division = (item) => {
        let data = exisitingRequisition?.filter((element) => element.divisionId === item.divisionName?._id)
        if (data?.length > 0) {
            return true
        }
        else {
            return false;
        }
    }
    const checkDisbale_RecruiterLeader = (item) => {
        let data = exisitingRequisition?.filter((element) => element.assignedTo === item.recruitmentLeader?.id)
        if (data?.length > 0) {
            return true
        }
        else {
            return false;
        }
    }

    const filteredRecruitmentLeaders = (item) => {
        let data = divisionName.filter((val) => val._id === item.divisionName?._id)
        if (data.length > 0) {
            data = data[0].teamDetails
            data = data.map((item) => item.recruiterLeaderDetails)
        }
        let filteredOptions = data.filter(itemVal => {
            let isAlreadySelected = false;
            item.recruitmentLeader.forEach(leader => {
                if (leader.id === itemVal.id) {
                    isAlreadySelected = true;
                }
            })
            return !isAlreadySelected;
        })
        return filteredOptions
    }

    const getFilteredDivision = () => {
        let arr = [];

        divisionName.forEach((item) => {
            let data = divisionLink.filter((element) => element.divisionName?._id === item._id)
            if (data.length === 0) {
                arr.push(item)
            }
        })
        return arr
    }

    useEffect(() => {
        if (userRecruitmentpartnerId) {
            getAllDivision();
            allClientsData();
        }
    }, [])

    return (
        <>
            {(userType === "Organization Admin" || userType === "MIS Operator" || (userType === 'Admin' && userRole === 'Organization Admin') || (userType === 'Admin' && userRole === 'MIS Operator')) ?
                <>
                    <NotificationContainer />
                    <Header />
                    <div className="section-wrapper">
                        <div className="container w1200 flex-to-footer">
                            <div className="height_adjust mt-110 manage-client-main">
                                <div className="header-client-manager">
                                    <div className="header-image-manage-client">
                                        <Typography className="manage-client-main-head">Manage Clients</Typography>
                                    </div>
                                    {userType === "Admin" ? "" :
                                        <Button className="add-button-manageclient"
                                            onClick={handleOpenDialog}
                                            startIcon={<img src={addSPOC} alt="Add Client" style={{ width: "16px", height: "16px" }} />}
                                        >
                                            Add Client
                                        </Button>
                                    }
                                </div>
                                <div className="goBackToRecruiterDashboard mt_10 " onClick={(e) => goToDashboard()}>
                                    <ArrowBackIcon />  &nbsp;Go Back to Dashboard
                                </div>
                                <ManageClientTable clientData={clientData} isDataLoaded={isDataLoaded} onEdit={handleEdit} userType={userType} />
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className='Unauthorized_container'>
                        <BasicHeader />
                        <div className="row padding_80 text-center">
                            <div className='UnAuthorized_contain'>
                                <p className="fs-semi-bold fs-40 f-color authorize">
                                    You Are Not Authorized To Visit This Page
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            }

            <Dialog
                open={openDialog}
                className="manage-client-modal"
                onClose={handleCloseDialog}
                aria-labelledby="dialog-title"
                maxWidth="sm"
                fullWidth
            >
                <div className="client_dialog_modal" >
                    <div className='client-dialog-TitleContainer'>
                        <img src={addSPOC} alt="Manage Client" className="iconStyle_client" />
                        <span className="dialog-title-text">{Object.keys(editedRow).length > 0 ? 'Edit Client' : 'Add Client'}</span>
                    </div>
                </div>
                <DialogContent className="client-dialog-content">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className="field-title-client-dialog">Client Name*</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter Client Name"
                                value={ClientInfo.name}
                                onChange={handleNameChange}
                                className="input-field-client-dialog"
                            />
                        </Grid>


                        {divisionLink.map((item, i) => (
                            <Grid container spacing={2} item xs={12} key={`clientsArr_${i}`}>
                                <Grid item xs={divisionLink.length > 1 ? 5 : 6}>
                                    <Typography className="field-title-client-dialog">
                                        Division Name*
                                    </Typography>
                                    <Autocomplete
                                        id="multiple-limit-tags"
                                        options={getFilteredDivision()}
                                        getOptionLabel={(option) =>
                                            option.divisionName ? option.divisionName : ""
                                        }
                                        closeIcon={null}
                                        value={item.divisionName}
                                        disabled={checkDisbale_division(item)}
                                        onChange={(e, val) => divisionChangeHandler(val, i)}
                                        filterSelectedOptions
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                required={true}
                                                placeholder="Division"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className="field-title-client-dialog">
                                        Recruitment Leader*
                                    </Typography>

                                    <FormControl fullWidth variant="outlined">
                                        <Autocomplete
                                            id="multiple-limit-tags"
                                            options={item.divisionName === "" ? [] : filteredRecruitmentLeaders(item)}
                                            getOptionLabel={(option) =>
                                                option.email ? option.email : ""
                                            }
                                            autoHighlight
                                            filterSelectedOptions
                                            multiple
                                            disabled={checkDisbale_RecruiterLeader(item)}
                                            closeIcon={null}
                                            value={item.recruitmentLeader}
                                            onChange={(e, val) => recruitmentLeaderChangeHandler(val, i)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    required={true}
                                                    placeholder="Recruitment Leader"
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Backspace') {
                                                            event.stopPropagation();
                                                        }
                                                    }}
                                                />
                                            }
                                            renderTags={(value, getTagProps) => {
                                                return (
                                                    value.map((option, index) => (
                                                        <Chip
                                                            label={option.email}
                                                            {...getTagProps({ index })}
                                                            onDelete={() => deleteRecruitmentLeaderHandler(option, i)}
                                                        />
                                                    ))
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    {divisionLink.length > 1 ?
                                        <Cancel className="cancelIcon_leadersArr" onClick={(e) => extraDivisionLinkClose(i, item)} />
                                        : ""}
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item xs={12} className="addButtonGrid_addLeadersArr">
                            <AddCircleIcon
                                color="primary"
                                onClick={(e) => addDiVisionLink(e)}
                                className="addIcon_leadersArr"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className="field-title-client-dialog">Address</Typography>
                            <TextField
                                multiline
                                fullWidth
                                variant="outlined"
                                minRows={3}
                                placeholder="Enter Address"
                                value={ClientInfo.address}
                                onChange={handleAddressChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className="field-title-client-dialog">Is Active*</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                value={ClientInfo.active}
                                options={activeDropDown}
                                disabled={editedRow ? isActiveFieldDisabled : false}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Active"
                                    />
                                )}
                                onChange={handleActiveChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className="dialog-actionbutton-client">
                    <button onClick={handleCloseDialog} className="cancel-button-client">
                        Cancel
                    </button>
                    <button
                        className={isUserDataUpdating ? 'updateButton-client_disabled' : 'updateButton-client'}
                        onClick={handleSubmit}
                        disabled={isUserDataUpdating}
                    >
                        <span>Save</span>
                        {isUserDataUpdating ? (<CircularProgress className="circularProgress_updateButton" />) : null}
                    </button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default ManageClient;