import React, { useState, useEffect, useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid as KendoGrid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from '@material-ui/lab/Skeleton'
import { NotificationContainer,NotificationManager } from "react-notifications";
import debounce from 'lodash/debounce';
import { useHistory } from "react-router";
import Header from "../../components/adminHeader/adminHeader";
import Headers from '../../components/headerAdmin/Header'
import AddUserModel from "./AddUserModel";
import EditUserModel from "./EditUserModel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { getAgencyType } from "../../apis/agency/agencyApi";
import getActiveRoles from "../../apis/users/getActiveRoles";
import seachUser from "../../apis/searchUser";
import { process } from "@progress/kendo-data-query";
import { UserTypeContext } from "../../AppRoute";
import "./userList.css";
import { FormControl, OutlinedInput, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  odd: {
    background: '#F6F6F6 !important'
  },
  even: {
    background: '#fffff !important'
  },
}))

const UserList = ({ idContextTest, props }) => {
  const { userType, userIdType, userRecords } = useContext(UserTypeContext);
  const id = userType === 'Admin' ? props?.location?.state?.id : userRecords?.id;
  const userRecruitmentpartnerId = userType === 'Admin' ? props?.location?.state?.userRecruitmentpartnerId : userRecords?.recruitmentpartnerId;
  const userRole = userType === 'Admin' ? props?.location?.state?.userRole : userRecords?.role?.roleName;
  const userRecrodData = userType === 'Admin' ? props?.location?.state?.userRecords : userRecords;
  const history = useHistory();
  const [allRoles, setallRoles] = useState([]);
  const [userRoleswithOutCandidate, setUserRoleWithoutCandidate] = useState([]);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const classes = useStyles()
  const [takeNumberOfResult, setTakeNumberOfResult] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [agencies, setAgencies] = useState([]);
  const [editFlag, setEditFlag] = useState(false)
  const [openAddModel, setOpenAddModel] = useState(false)
  const [isRefreshNeeded, setIsRefreshNeeded] = useState(false)
  const [openEditModel, setOpenEditModel] = useState(false)
  const [editUserData, setEditUserData] = useState({});
  const [roleWithoutCustomerAndAdmin, setRoleWithoutCustomerAndAdmin] = useState([]);
  const [filterValue, setFilterValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userType: {},
    isActive:"True"
  })
  const [searchValue, setSearchValue] = useState([]);

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [
        {
          field: "isActive",
          operator: "eq",
          value: true,
        },
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  const isActiveOption = ["True", "False"];
  const [appliedFilter, setAppliedFilter] = useState({})
  



  const btnGreen = {
    color: "#fff",
    backgroundColor: "#1885e6",
    border: "none",
    padding: "7px 23px 7px 23px",
    marginTop: "10px",
    marginBottom: "20px",
  };

  const btnEdit = {
    cursor: "pointer",
    color: "#fff",
    backgroundColor: "#1885e6",
  };

  const fieldsToField = [
    "firstName",
    "lastName",
    "email",
    "phoneNumber",
    "roleName",
    "isActive"

  ];

  useEffect(() => {
    const fetchAllData = async () => {
      try {
          await getAllRoles();
          await geAllAgencyType();
      } catch (error) {
        setisDataLoaded(true);
      } finally {
        setisDataLoaded(true);
      }

    }
    fetchAllData()
  }, []);

  useEffect(() => {
    const data = async () => {
      try {
        setIsSearching(true)
        await searchResultValue()

      } catch (error) {
        console.log("error", error);
        setIsSearching(false)
      }

    }
    data()


  }, [takeNumberOfResult, pageNumber])


  const dataStateChange = (event) => {
    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setPageNumber(page);
    setDataState(event.dataState);

  };


  const searchResultValue = async () => {
    setIsSearching(true)
    let payload = {
      size: takeNumberOfResult,
      page: pageNumber,
      filters: {
        firstName: appliedFilter?.firstName,
        lastName: appliedFilter?.lastName,
        email: appliedFilter?.email,
        userRole: appliedFilter?.userRole?.id || "",
        isActive:appliedFilter?.isActive || ""
      },
    }

    if (userType !== "Admin") {
      payload.userType = userType;
      payload.userRecruitmentpartnerId = userRecruitmentpartnerId;
    }
    try {
      let response = await seachUser(payload);      
      setSearchValue(response?.data)
      setTotalResult(response?.count)
      setIsSearching(false)
    } catch (error) {
      console.log(error, "error")
    }



  }

  const getAllRoles = async (userType) => {
    try {
      const allRoleResponse = await getActiveRoles();
      let roleArray = allRoleResponse.data.data;
      roleArray = roleArray.filter((item) => item.roleName !== "Candidate");
      setallRoles(roleArray);
      setUserRoleWithoutCandidate(roleArray);
      // Additional filtering based on userType if it's not "Admin"
      if (userType !== "Admin") {
        const filteredRoles = [];
        for (let i = 0; i < roleArray.length; i++) {
          const item = roleArray[i];
          const roleName = item?.roleName;
          if (roleName !== "Customer" && roleName !== "Admin") {
            filteredRoles.push(item);
          }
        }
        roleArray = filteredRoles;
      }
      setRoleWithoutCustomerAndAdmin(roleArray)

    } catch (error) {
      console.log(error);
    }
  };


  const openEditmodal = (event, userId, dataItem) => {    
    let selectedUserData = {
      id: "",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      country: "",
      phoneNumber: "",
      countryCallingCode: "",
      role: "",
      recruitmentPartner: "",
      agencyType: "",
      agency: ""
    }
    selectedUserData.id = dataItem?.id
    selectedUserData.firstName = dataItem?.firstName
    selectedUserData.middleName = dataItem?.MiddleInitial
    selectedUserData.lastName = dataItem?.lastName
    selectedUserData.email = dataItem?.email
    selectedUserData.phoneNumber = dataItem?.phoneNumber
    if (dataItem?.profile?.locationCountry) {
      selectedUserData.country = JSON.parse(dataItem?.profile?.locationCountry)
    }
    if (dataItem?.countryCode) {
      selectedUserData.countryCallingCode = dataItem?.countryCode
    }
    selectedUserData.role = dataItem?.role
    if (dataItem?.role.roleName !== "Candidate") {
      selectedUserData.recruitmentPartner = dataItem?.recruitmentpartner
      if ((dataItem?.agency)) {
        let agencyType_edit = agencies.filter((item) => item.id === dataItem.agency.agencyTypeId)
        selectedUserData.agencyType = agencyType_edit[0]
        selectedUserData.agency = dataItem.agency
      }
    }
    selectedUserData.status = dataItem?.isActive ? "True" : "False"
    setEditUserData(selectedUserData)
    setOpenEditModel(true)
  };


  const geAllAgencyType = async () => {
    try {
      const allAgency = await getAgencyType();
      setAgencies(allAgency.data.data);

    } catch (error) {
      console.log(error);
    }

  };

  const getSkeleton = () => {
    let arr = []
    for (let i = 0; i < 11; i++) {
      arr.push(<Skeleton className={i % 2 === 0 ? classes.odd : classes.even} variant="rect" width="100%" height="50px" />)
    }
    return arr;
  }

  const goToDashboard = () => {
    // Move to dashboard based on the user type
    if (userType === "Admin") {
      history.push("/admin-home");
    }
    else {
      history.push({
        pathname: "/dashboard",
      });
    }
  }


  const filterHandler = (event, field, value) => {
    let value1 = value || event?.target?.value
    setFilterValue({ ...filterValue, [field]: value1 })
  }

  useEffect(() => {
   
  })
  

  const filterSearch = async () => {
    if(filterValue.isActive === ""){
      return NotificationManager.error("Active field cannot be empty", "Error", 2000);
    }else{
      setIsSearching(true)
    setAppliedFilter(filterValue)
    let payload = {
      size: dataState.take,
      page: 1,
      filters: {
        firstName: filterValue?.firstName,
        lastName: filterValue?.lastName,
        email: filterValue?.email,
        userRole: filterValue?.userRole?.id || "",
        isActive:filterValue?.isActive || ""
      },
    }
    
    if (userType !== "Admin") {
      payload.userType = userType;
      payload.userRecruitmentpartnerId = userRecruitmentpartnerId;
    }    
    try {
      let response = await seachUser(payload);
      setSearchValue(response?.data)
      setTotalResult(response?.count)
      setDataState((prevState) => ({
        ...prevState,
        skip: 0, // Reset to page 1
    }));
    setIsSearching(false)
    } catch (error) {
      console.log(error, "error")
    }

    }
    

  }
 
  useEffect(() => {
    if(searchValue.length > 0 && editFlag){
      let data = searchValue.filter((elem) => elem?.isActive === (filterValue?.isActive === "True") ? true : false )
      setSearchValue(data)
      setTotalResult((prevCount) => Math.max(0, prevCount - 1));
      setEditFlag(false)
    }
        
  }, [editFlag])
  
  return (
    <>
      {userType === "Admin" ? <Header uid={idContextTest} isDataLoaded={isDataLoaded} /> : <Headers />}
      <div className="section-wrapper">
        <div className="container w1200 flex-to-footer">
          <div className="height_adjust mt-110">
            {!isDataLoaded && userType !== "Admin" ? (
              <div className="row padding_80 text-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {userType === "Admin" || userType === "Organization Admin" || userType === "MIS Operator" ? (
                  <div className="userlist_mainContainer">
                    <div className="users_top_section_wrapper">
                      <div>
                        <div className="userlist_heading">Users</div>
                        <div className="goBackToRecruiterDashboard mt_10 " onClick={(e) => goToDashboard()}>
                          <ArrowBackIcon />  &nbsp;Go Back to Dashboard
                        </div>
                      </div>
                      {userType === "Admin" ?
                        <div >
                          <Button
                            variant="outlined"
                            style={btnGreen}
                            color="primary"
                            onClick={(e) => setOpenAddModel(true)}
                          >
                            Create User
                          </Button>
                        </div> : null}
                    </div>
                    <div className="userlist_content_container">
                      <div className="leftSideMainContainer_user">
                        <div className="leftSideTopSection">
                          <div className="TopSectionLeftSide">
                            <div className="showingRF">Showing Results for</div>
                            <div className="showingRF">Manage User</div>
                          </div>
                          <div className="buttonContainer_search_recruiter">
                            <button className="button_search_recruiter" onClick={filterSearch}>Search</button>
                          </div>
                        </div>
                        <div className="leftSide_buttomSection">
                          <div className="leftside_contains_recruiter">
                            <Typography className="topography_front_recruiter">
                              First Name
                            </Typography>
                            <FormControl fullWidth variant="outlined">
                              <OutlinedInput
                                placeholder="Enter First Name"
                                className="outline_textbox_recruiter"
                                value={filterValue.firstName || ""}
                                onChange={(e) => filterHandler(e, "firstName")}
                              />
                            </FormControl>

                          </div>
                          <div className="leftside_contains_recruiter">
                            <Typography className="topography_front_recruiter">
                              Last Name
                            </Typography>
                            <FormControl fullWidth variant="outlined">
                              <OutlinedInput
                                placeholder="Enter Last Name"
                                className="outline_textbox_recruiter"
                                value={filterValue.lastName || ""}
                                onChange={(e) => filterHandler(e, "lastName")}
                              />
                            </FormControl>
                          </div>
                          <div className="leftside_contains_recruiter">
                            <Typography className="topography_front_recruiter">
                              Email
                            </Typography>
                            <FormControl fullWidth variant="outlined">
                              <OutlinedInput
                                placeholder="Enter Email"
                                className="outline_textbox_recruiter"
                                value={filterValue.email || ""}
                                onChange={(e) => filterHandler(e, "email")}
                              />
                            </FormControl>
                          </div>
                          {/* {tabName === "Pending" || tabName === "Revoked" ? null : <> */}
                          <div className="leftside_contains_recruiter">
                            <Typography className="topography_front_recruiter">
                              User Type
                            </Typography>
                            <Autocomplete
                              options={userType === "Admin" ? allRoles :roleWithoutCustomerAndAdmin}
                              getOptionLabel={(option) => option.roleName || ""}
                              value={filterValue.userRole}
                              onChange={(e, value) => filterHandler(e, "userRole", value)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Select User Type"
                                />
                              )}
                            />
                          </div>
                          <div className="leftside_contains_recruiter">
                            <Typography className="topography_front_recruiter">
                              Active
                            </Typography>
                            <Autocomplete
                              options={isActiveOption}
                              value={filterValue.isActive}
                              closeIcon
                              onChange={(e, value) => filterHandler(e, "isActive", value)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Select Is Active"
                                />
                              )}
                            />
                          </div>
                          {/* </>} */}
                        </div>

                      </div>
                      <div className="rightSideMainContainer_user">
                        <div className="userlist_content_container">
                          <div className="userlist_kendogridContainer">
                            <KendoGrid
                              filter={dataState.filter}
                              // filterable={true}
                              reorderable={true}
                              resizable={true}
                              pageable={{
                                buttonCount: 10,
                                info: true,
                                previousNext: true,
                                pageSizes: [10, 20, 50, 100],
                              }}
                              className="userlist_kendostyle"
                              total={totalResult}
                              skip={dataState.skip}
                              take={dataState.take}
                              data={searchValue}
                              onDataStateChange={dataStateChange}
                            >
                              <GridNoRecords>
                                {isSearching ? getSkeleton() : "No results found !"}
                              </GridNoRecords>
                              <Column
                                title="Edit"
                                filterable={false}
                                width="200px"
                                cell={(event) => {
                                  return (

                                    <td style={{ textAlign: "center" }}>
                                      <Button
                                        onClick={(e) => openEditmodal(e, event.dataItem.id, event.dataItem)}
                                        variant="contained"
                                        color="primary"
                                        style={btnEdit}
                                      >
                                        Edit
                                      </Button>
                                    </td>
                                  );
                                }}
                              />
                              <Column
                                field="firstName"
                                title="First Name"
                                width="250px"
                              />
                              <Column
                                field="lastName"
                                title="Last Name"
                                width="250px"
                              />
                              <Column
                                field="email"
                                title="Email"
                                width="300px"
                              />
                              <Column
                                field="phoneNumber"
                                title="Phone Number"
                                width="250px"
                              />
                              <Column
                                field="role.code"
                                title="User Type"
                                width="180px"
                              />

                              <Column
                                field="isActive"
                                title="Is Active"
                                width="180px"
                                filterable={true}
                                filter={"boolean"}
                                cell={(event) => {
                                  return <td>{event.dataItem?.isActive ? "True" : "False"}</td>;
                                }}
                              />


                            </KendoGrid>
                          </div>


                        </div>
                      </div>
                    </div>


                  </div>
                ) : userType === "" ? (<div className="row padding_80 text-center">
                  <p className="fs-semi-bold fs-40 f-color">
                    Loading...
                  </p>
                </div>) : (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      You Are Not Authorized To Visit This Page
                    </p>
                  </div>
                )}
              </>
            )}

            <AddUserModel
              openAddModel={openAddModel}
              setOpenAddModel={setOpenAddModel}
              isRefreshNeeded={isRefreshNeeded}
              setIsRefreshNeeded={setIsRefreshNeeded}
              userRoleswithOutCandidate={userRoleswithOutCandidate}
              agencies={agencies}
              userIdType={userIdType}
              isActiveOption={isActiveOption}
            />
            <EditUserModel
              openEditModel={openEditModel}
              isRefreshNeeded={isRefreshNeeded}
              setOpenEditModel={setOpenEditModel}
              userRoles={editUserData?.role?.roleName !== "Candidate" ? userRoleswithOutCandidate : allRoles}
              agencies={agencies}
              userIdType={userIdType}
              editUserData={editUserData}
              isActiveOption={isActiveOption}
              setIsRefreshNeeded={setIsRefreshNeeded}
              userType={userType}
              userRecruitmentpartnerId={userRecruitmentpartnerId}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setEditFlag={setEditFlag}
            />
            <NotificationContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;