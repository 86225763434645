import axios from "axios";
const jsonConfig = require("../Config.json");
const fflate = require('fflate');

const searchUser = async (payload) => {
  try {
      const Response = await axios.post(    
          jsonConfig.apiUrl+"searchuser",payload,
          {
              headers: {
                  "x-functions-key": jsonConfig.key,
              },
          }
      );
      let decompressedObject;
      if (Response?.data && Response?.data?.compressedbase64string) {
          const strData = atob(Response.data.compressedbase64string);
          const charData = strData.split("").map((x) => {
              return x.charCodeAt(0);
          });
          const binData = new Uint8Array(charData);

          let apiDataUnCompressed = fflate.unzlibSync(binData);
          let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
          decompressedObject = JSON.parse(apiDataUnCompressedString);
      } 
      return Promise.resolve(decompressedObject)
  }
  catch (error) {
      console.log("error", error);
      return Promise.reject(error);
  }
}

export default searchUser;